import React, { memo, useRef } from 'react';
import { serverURL } from '../constants';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ExitToApp from '@material-ui/icons/ExitToApp';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}


const useStyles = makeStyles({
  root: {
    background: '#ddd',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
  },
  card: {
    minWidth: 275,
    maxWidth: 400,
    flex: 1,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  input: {
    padding: 5,
    paddingLeft: 25,
    width: '100%',
  }
});

const Login = memo(props => {
  const classes = useStyles();
  const [alertMessage, setAlertMessage] = React.useState("Success");
  const [severity, setSeverity] = React.useState("success");
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });

  if (getCookie('access_token')) {
    console.log("access_token", getCookie('access_token'));
    props.onEmail(getCookie('email'));
    props.onToken(getCookie('access_token'));
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Think Insurcare Admin
          </Typography>
          <Typography variant="h5" component="h2">
            Login
          </Typography>
          <form className={classes.input}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity={severity}>
                {alertMessage}
              </Alert>
            </Snackbar>
            <FormControl style={{ width: '80%', marginBottom: 10 }}>
              <TextField id="standard-adornment" label="Email" onChange={handleChange('email')} />
            </FormControl>
            <FormControl style={{ width: '80%' }}>
              <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
              <Input
                id="standard-adornment-password"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </form>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<ExitToApp />}
            onClick={
              () => {
                console.log("email", values.email, "passwd", values.password);
                axios({
                  method: 'post',
                  url: serverURL + '/api/Users/login',
                  data: {
                    email: values.email,//'support@thinkinsurtech.com',
                    password: values.password,//'_@thinkthink'
                  }
                }).then(function (response) {
                  console.log("RESP:", response);
                  if (response.data.id) { // Success
                    setSeverity("success");
                    setOpen(true);
                    props.onEmail(values.email);
                    props.onToken(response.data.id);
                    document.cookie = "email=" + values.email + ';max-age=' + response.data.ttl;
                    document.cookie = "access_token=" + response.data.id + ';max-age=' + response.data.ttl;
                  } else {
                    setSeverity("warning");
                    setAlertMessage("Missing token");
                    setOpen(true);
                  }
                }).catch(function (error) {
                  setSeverity("error");
                  if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    setAlertMessage("Incorrect credentials");
                    setOpen(true);
                    console.log("ERR", error.response.data);
                    console.log("ERR", error.response.status);
                    console.log("ERR", error.response.headers);
                  } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    setAlertMessage()
                    console.log("ERR", error.request);
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                  }
                });
              }
            }
          >
            Login
          </Button>
        </CardActions>
      </Card>
    </div>
  );
});

export default Login;
