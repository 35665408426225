import React, { memo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MapIcon from '@material-ui/icons/Map';
import LinearProgress from '@material-ui/core/LinearProgress';

const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
}));

const RightMenu = memo(props => {
    const classes = useStyles();
    const show = props.show;
    const routesList = props.routesList;
    const beaconSelected = props.selected;
    const loading = props.loading;

    console.log("routesList:",routesList);
    console.log("selected:",beaconSelected);

    if(!show) return(<span></span>);

    if(show)
    return(
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
      >
        <div className={classes.toolbar} />
        <span style={{fontSize: 9, textAlign: 'center'}}>
            {beaconSelected}
        </span>
        <Divider />
        { (routesList!=null && routesList!=undefined && routesList.length >0) &&
        <List>
          {routesList.map((text, index) => (
            <ListItem button key={index} onClick={() => {
                props.onSelectedRoute(text.locations);
            }}>
              {/*<ListItemIcon><MapIcon /></ListItemIcon>*/}
              <ListItemText primary={text._id} secondary={text.timestamp+"\nTaille: "+text.__v} />
            </ListItem>
          ))}
        </List>
        }
        { loading==true &&
            <LinearProgress />
        }
      </Drawer>
    )
});

export default RightMenu;