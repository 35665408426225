import React, { useState, forwardRef, useEffect, memo } from 'react';
import './App.css';
import NavBar from './components/NavBar';
import LeftMenu from './components/LeftMenu'
import RightMenu from './components/RightMenu'
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { serverURL } from './constants';
import axios from 'axios';
import { BrowserRouter as Router, Route, NavLink, Switch } from "react-router-dom";

import Login from './components/login';
import Api from './components/Api';
import Servers from './components/Servers';
import Prospects from './components/Prospects';
import Clients from './components/Clients';
import PlansUpdater from './components/PlansUpdater';
import Dashboard from './components/dashboard';

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EuroIcon from '@material-ui/icons/Euro';
import PayIcon from '@material-ui/icons/Payment';
import DashIcon from '@material-ui/icons/Dashboard';
import APIIcon from '@material-ui/icons/SettingsInputComponent';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ListCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Tooltip, Collapse, Box, Button } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Cached';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';

import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import BusinessIcon from '@material-ui/icons/Business';
import DomainDisabledIcon from '@material-ui/icons/DomainDisabled';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DNSIcon from '@material-ui/icons/DnsTwoTone';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';

var ip = require('ip');

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const drawerWidth = 300;
const toolbarMargin = 50;
const TAX_RATE = 0.07;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "url(" + require("./images/background_1.jpg") + ")",
    backgroundPosition: 'center',
    transition: "all 2s",
    "&:hover": {
      //filter: 'invert(100%)',
      backgroundPosition: 'right',
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: toolbarMargin,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    marginTop: toolbarMargin,
    padding: theme.spacing(3),
  }
}));

function priceRow(qty, seat, unit) {
  return qty * unit;
}

function createRow(desc, qty, seat, unit, details, exp) {
  const price = priceRow(qty, seat, unit);
  return { desc, qty, seat, unit, price, details, exp };
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

var _email = null;
var _token = null;
var license = null;
var seats = null;
export function logs(action, element) {
  //console.log("token:", _token);
  //console.log("login:", _email);
  if (_token)
    axios({
      method: 'post',
      url: serverURL + '/api/logs',
      params: {
        access_token: _token
      },
      headers: {
        'authorization': `Bearer ${_token}`
      },
      data: {
        date: new Date().toISOString(),
        login: _email,
        action: action,
        element: element,
        IP: ip.address()
      }
    }).then(function (resp) {
      if (resp.status == 200) {
        const response = resp.data;
        //console.log("/api/logs",resp);
      } else {

      }
    }).catch(function (error) {
      //console.log("err logs",error);
    });
}

export const getLicense = () => {
  axios({
    method: 'get',
    url: serverURL + '/api/licenses',
    params: {
      access_token: _token
    },
    headers: {
      'authorization': `Bearer ${_token}`
    }
  }).then(function (resp) {
    if (resp.status == 200) {
      const response = resp.data;
      //console.log("/api/licenses",response);
      license = response;
    }
  }).catch(function (error) {
    console.log("err", error);
    logout();
  });
};

export const getSeats = () => {
  axios({
    method: 'get',
    url: serverURL + '/api/seats',
    params: {
      access_token: _token
    },
    headers: {
      'authorization': `Bearer ${_token}`
    }
  }).then(function (resp) {
    console.log('seat resp', resp);
    if (resp.status == 200) {
      const response = resp.data;
      //console.log("/api/seats",response);
      seats = response;
    }
  }).catch(function (error) {
    console.log("err", error);
    logout();
  });
};

export const logout = () => {
  document.cookie = "access_token=";
  document.cookie = "email=";
  document.location = '';
};

const App = memo(props => {
  const classes = useStyles();
  const [email, setEmail] = useState("-");
  const [selectedURL, setSelectedURL] = useState(null);
  const [status, setStatus] = useState(0);
  const [page, setPage] = useState("Dashboard");
  const [broker, setBroker] = useState({ "name": "", "id": 0 });
  const [brokersList, setBrokersList] = useState(null);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [pipedrive, setPipedrive] = React.useState(null);
  const [pandadoc, setPandadoc] = useState(null);
  const [pandadocuments, setPandadocuments] = useState(null);
  const [search, setSearch] = useState(null);
  const [dataPie, setDataPie] = useState([]);
  const [authToken, setAuthToken] = useState(null);
  const [logHistory, setLogHistory] = useState(null);
  const [serverList, setServers] = React.useState([]);

  const handleChange = (event) => {
    setSearch(event.target.value.toUpperCase());
  }

  const getAPI = () => {
    axios({
      method: 'get',
      url: serverURL + '/api/apis',
      params: {
        access_token: _token
      },
      headers: {
        'authorization': `Bearer ${_token}`
      }
    }).then(function (resp) {
      if (resp.status == 200) {
        const response = resp.data;
        //console.log("/api/apis",response);

        const pipedrive = response.find(el => el.type == "pipedrive");
        if (pipedrive) {
          setPipedrive(pipedrive);
        } else { setPipedrive({}); }

        const pandadoc = response.find(el => el.type == "pandadoc");
        if (pandadoc) {
          setPandadoc(pandadoc);
        } else { setPandadoc({}); }
      }
    }).catch(function (error) {
      console.log("err", error);
    });
  };

  const getPandaDocuments = () => {
    axios({
      method: 'get',
      url: 'https://api.pandadoc.com/public/v1/documents',
      headers: {
        Authorization: 'API-Key ' + pandadoc.token
      }
    }).then(function (resp) {
      if (resp.status == 200) {
        console.log("get PandaDoc /documents", resp.data.results);
        setPandadocuments(resp.data.results);
      } else {

      }
    }).catch(function (error) {

    });
  };

  useEffect(() => {
    if (pandadoc !== null)
      getPandaDocuments();
  }, [pandadoc]);

  const getClients = () => {
    axios({
      method: 'get',
      url: serverURL + '/api/clients',
      params: {
        access_token: _token
      },
      headers: {
        'authorization': `Bearer ${_token}`
      }
    }).then(function (resp) {
      if (resp.status == 200) {
        console.log("CLIENTS:", resp);
        let list = [];

        resp.data.map((text, index) => {
          console.log("push", text);
          list.push({ name: text.name, id: text.id, DealID: text.DealID, trial: text.trial, type: text.type, email: text.email, phone: text.phone, server: text.URL });

          //if(props.match.params.id) setBroker({name: text.name, id: text.id, DealID: text.DealID, trial: text.trial, type: text.type, email: text.email, phone: text.phone, server: text.server});
        });
        console.log("list:", list);
        setBrokersList(list);
        //console.log("save list");
      } else if (resp.status == 401) {
        logout();
      }
    }).catch(function (error) {
      logout();
    });
  };

  console.log('brokersList', brokersList);

  const getServers = () => {
    console.log("getServers <--");
    axios({
      method: 'get',
      url: serverURL + '/api/servers',
      params: {
        access_token: _token
      },
      headers: {
        'authorization': `Bearer ${_token}`
      }
    }).then(function (resp) {
      console.log("/api/servers", resp);
      console.log("status:", resp.status);
      console.log("data:", resp.data);
      if (resp.status == 200) {
        const response = resp.data;
        setServers(response);
      }
    }).catch(function (error) {
      console.log("err getServers", error);
    });
  };

  console.log("authToken", authToken);
  useEffect(() => {
    if (authToken !== null) {
      _token = authToken;
      _email = email;
      if (!brokersList) getClients();
      if (!license) getLicense();
      if (!seats) getSeats();
      if (!pipedrive) getAPI();
      getServers();
    }
  }, [authToken]);

  useEffect(() => {
    if (license !== null && seats !== null) {
      let trialc = 0, starterc = 0, proc = 0, infc = 0;
      let trials = 0, starters = 0, pros = 0, infs = 0;
      let starteramount = 0, proamount = 0, infamount = 0, trialamount = 0;
      let triald = [], starterd = [], prod = [], infd = [];
      let trialexp = [], starterexp = [], proexp = [], infexp = [];
      for (var a = (license.length - 1); a > 0; a--) {
        if (license[a].type === "starter") starteramount += license[a].amount;
        if (license[a].type === "pro") proamount += license[a].amount;
        if (license[a].type === "infinite") infamount += license[a].amount;
        if (license[a].type === "trial") trialamount += license[a].amount;
        for (var b = 0; b < seats.length; b++) {
          if (license[a].clientID === seats[b].clientID) {
            if (license[a].type === "starter") {
              starters++;
              if (brokersList !== null)
                for (var c = 0; c < brokersList.length; c++) {
                  if (license[a].clientID === brokersList[c].id) {
                    starterd.push(brokersList[c].name + " : " + seats[b].firstname + ' ' + seats[b].lastname + " > " + new Date(license[a].endDate).toLocaleString());
                    var now = new Date();
                    if (new Date(license[a].endDate) < now)
                      starterexp.push(true);
                    else starterexp.push(false);
                  }
                }
            }
            if (license[a].type === "pro") {
              pros++;
              if (brokersList !== null)
                for (var c = 0; c < brokersList.length; c++) {
                  if (license[a].clientID === brokersList[c].id) {
                    prod.push(brokersList[c].name + " : " + seats[b].firstname + ' ' + seats[b].lastname + " > " + new Date(license[a].endDate).toLocaleString());
                  }
                }
            }
            if (license[a].type === "infinite") {
              infs++;
              if (brokersList !== null)
                for (var c = 0; c < brokersList.length; c++) {
                  if (license[a].clientID === brokersList[c].id) {
                    infd.push(brokersList[c].name + " : " + seats[b].firstname + ' ' + seats[b].lastname + " > " + new Date(license[a].endDate).toLocaleString());
                  }
                }
            }
            if (license[a].type === "trial") {
              trials++;
              if (brokersList !== null)
                for (var c = 0; c < brokersList.length; c++) {
                  if (license[a].clientID === brokersList[c].id) {
                    let topush = brokersList[c].name + " : " + seats[b].firstname + ' ' + seats[b].lastname + " > " + new Date(license[a].endDate).toLocaleString();
                    let found = triald.filter(i => i.includes(seats[b].firstname + " " + seats[b].lastname));
                    let founds = starterd.filter(i => i.includes(seats[b].firstname + " " + seats[b].lastname));
                    let foundp = prod.filter(i => i.includes(seats[b].firstname + " " + seats[b].lastname));
                    let foundi = infd.filter(i => i.includes(seats[b].firstname + " " + seats[b].lastname));

                    if (found.length || founds.length || foundi.length || foundp.length) {
                      console.log("found"); //RIEN
                    }
                    else {
                      triald.push(topush);
                      var now = new Date();
                      var soon = new Date();
                      soon.setDate(soon.getDate() + 5);
                      if (new Date(license[a].endDate) < now)
                        trialexp.push(2); // EXP
                      else if (new Date(license[a].endDate) < soon)
                        trialexp.push(1); // SOON
                      else trialexp.push(0); // OK
                    }
                  }
                }
            }
          }
        }

        if (license[a].type === "trial") trialc++;
        if (license[a].type === "starter") starterc++;
        if (license[a].type === "pro") proc++;
        if (license[a].type === "infinite") infc++;
      }

      console.log("exp", trialexp);

      setRows([
        createRow('THINK INSURTECH - STARTER EDITION - FREE TRIAL', trialc, trials, 0, triald, trialexp),
        createRow('THINK INSURTECH - STARTER (OLD PRICE)', starterc, starters, 300, starterd, starterexp),
        createRow('THINK INSURTECH - STARTER 3 LICENSES', [], [], 399, [], []),
        createRow('THINK INSURTECH - PRO 5 LICENSES', proc, pros, 799.00, prod, proexp),
      ]);
      setDataPie([
        { name: 'FREE TRIAL', value: trials, profit: trialamount },
        { name: 'STARTER', value: starters, profit: starteramount },
        { name: 'PRO', value: pros, profit: proamount },
        { name: 'INFINITE', value: infs, profit: infamount },
      ]);
    }
  }, [seats, license]);

  if (!authToken) {
    return (<Login
      onToken={(token) => {
        setAuthToken(token);
      }}
      onEmail={(email) => {
        setEmail(email);
      }}
    />);
  }

  const selected = {
    fontWeight: "bolder",
    fontSize: 18,
    color: '#999'
  };

  console.log("liste des brokers:", brokersList);

  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" noWrap>
              <NavLink exact strict to={"/"} style={{ textDecoration: 'none', color: '#fff' }}>
                <img alt="logo" src={require('./images/logo.png')} style={{ height: 100, padding: 3, }} />
                <span style={{ position: 'absolute', fontSize: 18, top: 32, textShadow: '0px 0px 4px white' }}>Think Insurcare Super Administration Panel</span>
              </NavLink>
              <span style={{ fontSize: 12, color: '#ccc' }}>{email} • <a style={{ textDecoration: 'underline', color: '#fff', cursor: 'pointer' }} onClick={() => { logout(); }}>logout</a></span>
            </Typography>
            <span style={{ position: 'absolute', right: 10 }}>
              <Tooltip title={brokersList !== null ? brokersList.filter(text => text.type !== "client").length + " prospects" : "no prospects"}>
                <IconButton aria-label="show 17 new notifications" color="inherit">
                  <Badge badgeContent={brokersList !== null ? brokersList.filter(text => text.type !== "client").length : 0} color="secondary">
                    <DomainDisabledIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Tooltip title={brokersList !== null ? brokersList.filter(text => text.type === "client").length + " clients" : "no clients"}>
                <IconButton aria-label="show 17 new notifications" color="inherit">
                  <Badge badgeContent={brokersList !== null ? brokersList.filter(text => text.type === "client").length : 0} color="secondary">
                    <BusinessIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Tooltip title={seats !== null ? seats.length + " seats" : "no seats"}>
                <IconButton aria-label="show 17 new notifications" color="inherit">
                  <Badge badgeContent={seats !== null ? seats.length : 0} color="secondary">
                    <AssignmentIndIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Tooltip title={license !== null ? license.filter(text => text.amount > 0).length + " paying licenses" : "no paying licenses"}>
                <IconButton aria-label="show 4 new mails" color="inherit">
                  <Badge badgeContent={license !== null ? license.filter(text => text.amount > 0).length : 0} color="secondary">
                    <EuroIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Tooltip title={"no payments"}>
                <IconButton aria-label="show 4 new mails" color="inherit">
                  <Badge badgeContent={0} color="secondary">
                    <PayIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
            </span>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <div className={classes.drawerContainer}>
            <List>
              {['Dashboard',
                'API Configuration',
                'Prospects from Pipedrive',
                'Servers manager',
                //'Plans Updater'
              ].map((text, index) => (
                <NavLink strict to={"/" + text} style={{ textDecoration: 'none', color: '#333' }} activeStyle={selected}>
                  <ListItem button key={text} onClick={() => { setPage(text); }} selected={page == text ? true : false}>
                    <ListItemIcon>
                      {index === 0 && <DashIcon />}
                      {index === 1 && <APIIcon />}
                      {index === 2 && <ListCheckIcon />}
                      {index === 3 && <DNSIcon />}
                      {index === 4 && <AssignmentReturnedIcon />}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItem>
                </NavLink>
              ))}
            </List>
            {brokersList === null &&
              <div style={{ padding: 20 }}>
                <CircularProgress />
              </div>
            }
            {brokersList !== null &&
              <span>
                <TextField
                  id="filled-search"
                  label="Search"
                  type="search"
                  onChange={handleChange}
                  style={{ width: '90%', margin: 10 }}
                  variant="outlined" />
                <List
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      Prospects
                    </ListSubheader>
                  }>
                  {brokersList.filter(text => text.type !== "client").filter(text => search !== null ? text.name.toUpperCase().includes(search) : text).map((text, index) => (
                    <NavLink exact strict to={"/Client/" + text.id + "/" + text.name} style={{ textDecoration: 'none', color: '#333' }} activeStyle={selected}>
                      <ListItem button key={index} onClick={() => { setPage("broker"); setBroker({ name: text.name, id: text.id, DealID: text.DealID, trial: text.trial, type: text.type, email: text.email, phone: text.phone, server: text.server }); }} selected={broker.id == text.id && page == "broker" ? true : false}>
                        <ListItemIcon>
                          {text.id === 1 && <img alt="logo" src={require('./images/brokers/0.png')} style={{ height: 40, padding: 0 }} />}
                          {text.id !== 1 && <SupervisedUserCircleIcon />}
                        </ListItemIcon>
                        <ListItemText primary={text.name} />
                      </ListItem>
                    </NavLink>
                  ))}
                </List>
                <Divider />
                <List
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      Clients
                    </ListSubheader>
                  }>
                  {brokersList.filter(text => text.type === "client").filter(text => search !== null ? text.name.toUpperCase().includes(search) : text).map((text, index) => (
                    <NavLink exact strict to={"/Client/" + text.id + "/" + text.name} style={{ textDecoration: 'none', color: '#333' }} activeStyle={selected}>
                      <ListItem button key={index} onClick={() => { setPage("broker"); setBroker({ name: text.name, id: text.id, DealID: text.DealID, trial: text.trial, type: text.type, email: text.email, phone: text.phone, server: text.server }); }} selected={broker.id == text.id && page == "broker" ? true : false}>
                        <ListItemIcon>
                          {text.id === 1 && <img alt="logo" src={require('./images/brokers/0.png')} style={{ height: 40, padding: 0 }} />}
                          {text.id !== 1 && <SupervisedUserCircleIcon />}
                        </ListItemIcon>
                        <ListItemText primary={text.name} />
                      </ListItem>
                    </NavLink>
                  ))}
                </List>
              </span>
            }
            <div style={{ margin: 40 }}></div>
          </div>
        </Drawer>
        {/* ======================================================================================================================= */}
        <main className={classes.content}>
          <Toolbar />
          {
            <Switch>
              <Route exact strict path="/API Configuration">
                <Api authToken={authToken} />
              </Route>
              <Route exact strict path="/Servers manager">
                <Servers authToken={authToken} ServerList={serverList} onUpdate={() => { getServers(); }} />
              </Route>
              <Route exact strict path="/Prospects from Pipedrive">
                <Prospects authToken={authToken} onAdd={() => { getClients(); }} />
              </Route>
              <Route exact strict path="/Plans Updater">
                <PlansUpdater authToken={authToken} />
              </Route>
              <Route exact strict path="/Client/:id/:name" render={(props) =>
                <Clients
                  authToken={authToken}
                  id={props.match.params.id}
                  name={props.match.params.name}
                  ServerList={serverList}
                  Server={broker.server ? broker.server : 0}
                  DealID={broker.DealID}
                  licenseEndDate={broker.licenseEndDate}
                  licenseDate={broker.licenseDate}
                  type={broker.type}
                  trial={broker.trial}
                  email={broker.email}
                  phone={broker.phone}
                  pandadocuments={pandadocuments !== null ? pandadocuments.filter(doc => doc !== null ? doc.name.toLowerCase().includes(String(broker.name).toLowerCase()) : null) : []}
                  onUpdate={() => { getClients(); }}
                  onDel={() => { getClients(); }}
                />
              } />
              <Route exact strict path="/Log History">

              </Route>
              <Route path="/">
                <Dashboard authToken={authToken} rows={rows} dataPie={dataPie} />
              </Route>
            </Switch>
          }
        </main>
      </div>
    </Router>
  );
});

export default App;
