import React, { memo, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import List from '@material-ui/core/List';
import Public from '@material-ui/icons/Public';
import { serversList } from '../constants';

const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
}));

const LeftMenu = memo(props => {
    const classes = useStyles();
    const selectedURL = props.selectedURL;
    const beaconList = props.beaconList;

    return(
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar} />
        <List>
          {serversList.map((text, index) => (
            <ListItem button key={text} onClick={() => { props.onSelectedURL(index); }} disabled={selectedURL==index?true:false}>
              <ListItemIcon><Public /></ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        { beaconList &&
        <List>
          {beaconList.data.map((text, index) => (
            <ListItem button key={index} onClick={() => { props.onSelectedBeacon(text); }}>
              <ListItemIcon><SettingsRemoteIcon /></ListItemIcon>
              <ListItemText primary={text.beaconUuid} secondary={text.macAddress}/>
            </ListItem>
          ))}
        </List>
        }
      </Drawer>
    )
});

export default LeftMenu;