import React, { memo, useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, lighten, withStyles } from '@material-ui/core/styles';
import { serversList, serverURL } from '../constants';
import { logs } from '../App';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import qs from 'qs';
import { green } from '@material-ui/core/colors';
import {
  FormHelperText, TableContainer, Input, InputLabel, FormControl,
  CircularProgress, Checkbox, FormControlLabel, Button, NativeSelect,
  Table, TableBody, TableCell, TableHead, Tooltip, IconButton,
  Select, MenuItem
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FilterListIcon from '@material-ui/icons/FilterList';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import Document from '@material-ui/icons/Description';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ExitToApp from '@material-ui/icons/ExitToApp';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import MailIcon from '@material-ui/icons/Mail';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import ForumIcon from '@material-ui/icons/Forum';

const tableIcons = {
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ExitToApp: forwardRef((props, ref) => <ExitToApp {...props} ref={ref} />),
  PersonAddIcon: forwardRef((props, ref) => <PersonAddIcon {...props} ref={ref} />)
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

const adminType = (type) => {
  switch (type) {
    case "broker": return "BROKER";
    case "adminpro": return "ADMIN PRO";
  }
}

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  block: {
    background: '#eee',
    padding: 10,
    marginBottom: 10
  },
  input: {
    paddingLeft: 10,
    paddingBottom: 20,
    width: '100%'
  },
  delete: {
    float: "right",
    marginTop: -50
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

const PandaButton = withStyles((theme) => ({
  root: {
    color: '#fff',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);

const Clients = memo(props => {
  const classes = useStyles();
  const [pipedrive, setPipedrive] = React.useState(null);
  const [pandadoc, setPandadoc] = useState(null);
  const [seats, setSeats] = useState([]);
  const [license, setLicense] = useState([]);
  const [showMail, setShowMail] = useState(false);
  const [backend, setBackend] = useState("-");
  const [alertMessage, setAlertMessage] = React.useState("Success");
  const [severity, setSeverity] = React.useState("success");
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [marketplaceToken, setMarketplaceToken] = useState(null);
  const [ID, setID] = useState(null);

  const [credentialsEmail, setCredentialsEmail] = useState("test@thinkinsurtech.com");
  const [credentialsPassword, setCredentialsPassword] = useState("@test123think");

  const [type, setType] = React.useState(props.type);

  console.log("type", props.type);
  console.log("client", props.client);

  React.useEffect(() => {
    if (props.type) setType(props.type);
  }, [props.type])



  const servers = props.ServerList;
  const [selectedServ, setSelectedServ] = useState(props.Server);

  console.log("selectedServ:", selectedServ);

  console.log("ServList", servers);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  // console.log('========servers=======', selectedServ, servers, servers[selectedServ]);
  useEffect(() => {
    if (servers && servers.length && servers[selectedServ] !== undefined) {
      setCredentialsEmail(servers[selectedServ]?.masterCredentialsEmail);
      setCredentialsPassword(servers[selectedServ]?.masterCredentialsPassword);
      setBackend(servers[selectedServ]?.backendCorporate);
      // console.log("selectedServ:sss", servers[selectedServ]?.backendCorporate);
      // setBackend('https://backend.thinkinsurcare.com/api')
    }
  }, [selectedServ, servers]);

  const getAPI = () => {
    axios({
      method: 'get',
      url: serverURL + '/api/apis',
      params: {
        access_token: props.authToken
      },
      headers: {
        'authorization': `Bearer ${props.authToken}`
      }
    }).then(function (resp) {
      if (resp.status == 200) {
        const response = resp.data;
        console.log("/api/apis", response);

        const pipedrive = response.find(el => el.type == "pipedrive");
        if (pipedrive) {
          setPipedrive(pipedrive);
        } else { setPipedrive({}); }

        const pandadoc = response.find(el => el.type == "pandadoc");
        if (pandadoc) {
          setPandadoc(pandadoc);
        } else { setPandadoc({}); }
      }
    }).catch(function (error) {
      console.log("err", error);
    });
  };

  const getLicense = () => {
    axios({
      method: 'get',
      url: serverURL + '/api/licenses',
      params: {
        access_token: props.authToken,
        filter: { "where": { "clientID": props.id } }
      },
      headers: {
        'authorization': `Bearer ${props.authToken}`
      }
    }).then(function (resp) {
      if (resp.status == 200) {
        const response = resp.data;
        console.log("/api/licenses", response);
        setLicense(response.reverse());
      }
    }).catch(function (error) {
      console.log("err", error);
    });
  };

  const getSeats = () => {
    axios({
      method: 'get',
      url: serverURL + '/api/seats',
      params: {
        access_token: props.authToken,
        filter: { "where": { "clientID": props.id } }
      },
      headers: {
        'authorization': `Bearer ${props.authToken}`
      }
    }).then(function (resp) {
      if (resp.status == 200) {
        const response = resp.data;
        console.log("/api/seats", response);
        setSeats(response);
      }
    }).catch(function (error) {
      console.log("err", error);
    });
  };
  /*
      const addMarketplaceUser = () => {
        axios({
          method: 'post',
          url: 'https://marketplace.thinkinsurtech.com/api/v1/oauth/token',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          data: qs.stringify({
            "grant_type": "password",
            "client_id": "5fc6454256cfb1c8e0ab0c32-g1UHVz3S3xwlJasSnwDxURuvglQXVCWd",
            "client_secret": "AaW8feX4ZqwCwxVr2prG3V0wxFCylQ3C04UnQQbwvmLTml1LzlWdpYBhHEy0K7uY",
            "username": "support@thinkinsurtech.com",
            "password": "cif3EyyvfC!#M7!"
          })
        }).then(function (resp) {
          if(resp.status == 200){
            const response = resp.data;
            console.log("marketplace.thinkinsurtech.com/api/v1/oauth/token",response);
  
            console.log("token:",response.access_token);
            let token = response.access_token;
  
              axios({
                method: 'post',
                url: 'https://marketplace.thinkinsurtech.com/api/users/',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                data: qs.stringify({
                  "username": "password",
                  "name": "5fc6454256cfb1c8e0ab0c32-g1UHVz3S3xwlJasSnwDxURuvglQXVCWd",
                  "email": "AaW8feX4ZqwCwxVr2prG3V0wxFCylQ3C04UnQQbwvmLTml1LzlWdpYBhHEy0K7uY",
                  "password": "support@thinkinsurtech.com",
                  "confirmPassword": "cif3EyyvfC!#M7!",
                  "role": "member"
                })
              }).then(function (resp) {
                if(resp.status == 200){
                  const response = resp.data;
                  console.log("marketplace.thinkinsurtech.com/api/v1/oauth/token",response);
  
                }
              }).catch(function (error) {
                console.log("err",error);
              });
          }
        }).catch(function (error) {
          console.log("err",error);
        });
      };
  */
  const getActualLicense = (license) => {
    //console.log("license is",license);
    if (!license.length) return 'no license';
    if (new Date(license[0].endDate) > new Date()) {
      return license[0].type;
    } else {
      return "expired";
    }
  };

  useEffect(() => {
    getAPI();
    getLicense();
    getSeats();
  }, []);

  if (ID === null || ID != props.id) {
    setType(props.type);
    setID(props.id);
    setLicense(null);
    setSeats(null);
    getAPI();
    getLicense();
    getSeats();
  }

  return (
    <TableContainer component={Paper} style={{ padding: 20 }}>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Transition modal</h2>
            <p id="transition-modal-description">react-transition-group animates me.</p>
          </div>
        </Fade>
      </Modal>
      <h2>{props.name}</h2>
      {props.DealID && props.DealID > 0 &&
        <FormControl style={{ position: 'absolute', right: 55, marginTop: -50 }}>
          <Button variant="contained" onClick={() => {
            if (window.confirm('Delete this client ? This is irrevocable.')) {
              console.log("confirm");
              axios({
                method: 'delete',
                url: serverURL + '/api/clients/' + props.id,
                params: {
                  access_token: props.authToken
                },
                headers: {
                  'authorization': `Bearer ${props.authToken}`
                }
              }).then(function (resp) {
                if (resp.status == 200) {
                  setSeverity("success");
                  setAlertMessage("Saved");
                  setOpen(true);
                  logs("delete", "client Name:" + props.name + " dealID:" + props.DealID + " type:" + props.type);
                  props.onDel();
                } else {
                  setSeverity("error");
                  setAlertMessage("error");
                  setOpen(true);
                }
                console.log("resp", resp);
              }).catch(function (error) {
                console.log("err", error);
                setSeverity("error");
                setAlertMessage("error");
                setOpen(true);
              })
            }
            else {
              console.log("else");
            }
          }}><DeleteIcon /> Delete</Button>
        </FormControl>
      }
      <div className={classes.block}>
        <h3>Overview</h3>
        <div style={{ flexDirection: 'row', flex: 1 }}>
          {type &&
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                style={{ minWidth: 150 }}
                value={type}
                onChange={(event) => {
                  axios({
                    method: 'patch',
                    url: serverURL + '/api/clients',
                    params: {
                      access_token: props.authToken
                    },
                    headers: {
                      'authorization': `Bearer ${props.authToken}`
                    },
                    data: {
                      DealID: props.DealID,
                      lastUpdate: new Date().toISOString(),
                    }
                  }).then(function (resp) {
                    if (resp.status == 200) {
                      setSeverity("success");
                      setAlertMessage("Saved");
                      setOpen(true);
                    } else {
                    }
                    console.log("resp", resp);
                  }).catch(function (error) {
                  });
                  props.onUpdate();
                }}
              >
                <MenuItem value={"prospect"}>Prospect</MenuItem>
                <MenuItem value={"client"}>Client</MenuItem>
              </Select>
            </FormControl>
          }

          {servers &&
            <FormControl className={classes.formControl} style={{ marginLeft: 5 }}>
              <InputLabel id="demo-simple-select-label">Server [{backend}]</InputLabel>
              {<Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                style={{ minWidth: 150 }}
                value={selectedServ ? (selectedServ) : 6}
                onChange={(event) => {
                  axios({
                    method: 'patch',
                    url: serverURL + '/api/clients/' + props.id,
                    params: {
                      access_token: props.authToken
                    },
                    headers: {
                      'authorization': `Bearer ${props.authToken}`
                    },
                    data: {
                      lastUpdate: new Date().toISOString(),
                      URL: (event.target.value).toString()
                    }
                  }).then(function (resp) {
                    if (resp.status == 200) {
                      setSeverity("success");
                      setAlertMessage("Server changed to " + event.target.value);
                      setOpen(true);
                    } else {
                    }
                    console.log("resp", resp);
                  }).catch(function (error) {
                  });
                  setSelectedServ(event.target.value);
                  //props.onUpdate();
                }}
              >
                {servers.map((dom, index) => (
                  <MenuItem value={index}>{index} - {dom.name} - {dom.frontCorporate}</MenuItem>
                ))}
              </Select>}
            </FormControl>
          }
          {license !== null &&
            <span style={{ display: 'inline-block' }}>
              <div style={{ fontSize: 10, color: '#666' }}>Actual License</div>
              {getActualLicense(license) === "expired" || getActualLicense(license) === "no license" ? <ClearIcon /> : <DoneIcon />}
              <span style={{ fontSize: 25, fontWeight: 'bold', color: getActualLicense(license) === "expired" ? 'red' : (getActualLicense(license) === "no license" ? "#888" : "green") }}>{getActualLicense(license)}</span>
            </span>
          }
        </div>
        {props.trial &&
          <div>
            Trial : <span style={{ color: 'green', fontWeight: 'bold' }}>{props.trial} days</span>
          </div>
        }
        {props.email &&
          <div>
            MAIL : <span style={{ color: 'green', fontWeight: 'bold' }}>{props.email}</span>
          </div>
        }
        {props.phone &&
          <div>
            PHONE : <span style={{ color: 'green', fontWeight: 'bold' }}>{props.phone}</span>
          </div>
        }
        {props.DealID && props.DealID > 0 &&
          <FormControl style={{ padding: 7 }}>
            <Button variant="contained" color="primary" onClick={() => {
              window.open(pipedrive.URL + "deal/" + props.DealID, '_blank');
            }}><Search /> View in Pipedrive (DEAL #{props.DealID})</Button>
          </FormControl>
        }
        {props.pandadocuments.filter(doc => doc.name.toLowerCase().includes(String(props.name).toLowerCase())).map((doc, index) => (
          <FormControl key={index} style={{ padding: 7 }}>
            <PandaButton variant="contained" color="primary" onClick={() => {
              window.open("https://app.pandadoc.com/a/#/document/v1/editor/" + doc.id + "/content", '_blank');
            }}><Document /> {doc.name}</PandaButton>
          </FormControl>
        ))}
      </div>
      {license === null &&
        <CircularProgress disableShrink />
      }
      {license !== null &&
        <MaterialTable
          title="License Manager"
          columns={[
            { title: 'Start Date', field: 'startDate', cellStyle: { minWidth: 125 }, type: 'date' },
            { title: 'End Date', field: 'endDate', cellStyle: { minWidth: 125 }, type: 'date', editable: 'never' },
            { title: 'Duration (days)', field: 'daysDuration', type: 'numeric' },
            { title: 'License', field: 'type', lookup: { 'trial': 'Trial', 'starter': 'Starter', 'pro': 'Pro', 'infinite': 'Infinite' }, initialEditValue: 'trial' },
            { title: 'Amount', field: 'amount', type: 'numeric' },
            { title: 'Due', field: 'due', type: 'numeric' }
          ]}
          data={license ? license : []}
          icons={{
            ...tableIcons,
            Add: forwardRef((props, ref) => <button style={{ padding: 2 }}><AddBox {...props} ref={ref} style={{ fontSize: 35, color: 'green' }} /><div style={{ fontSize: 16 }}>Add License</div></button>)
          }}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  let startDate = new Date(newData?.startDate);
                  startDate.setDate(startDate.getDate() + Number.parseInt(newData.daysDuration)); //newData.daysDuration
                  let endDate = startDate?.toISOString();
                  console.log(endDate);
                  console.log("put /api/licenses", {
                    clientID: props.id,
                    startDate: newData?.startDate,
                    endDate: endDate,
                    daysDuration: newData?.daysDuration,
                    type: newData?.type,
                    amount: newData?.amount,
                    due: newData?.due,
                    createDate: new Date().toISOString()
                  });
                  axios({
                    method: 'put',
                    url: serverURL + '/api/licenses',
                    params: {
                      access_token: props.authToken
                    },
                    data: {
                      clientID: parseInt(props.id),
                      startDate: newData.startDate || undefined,
                      endDate: endDate || undefined,
                      daysDuration: parseInt(newData.daysDuration) || undefined,
                      type: newData.type || undefined,
                      amount: parseInt(newData.amount) || undefined,
                      due: parseInt(newData.due) || undefined,
                      createDate: new Date().toISOString()
                    },
                    headers: {
                      'authorization': `Bearer ${props.authToken}`
                    }
                  }).then(function (resp) {
                    if (resp.status == 200) {
                      const response = resp.data;
                      console.log("/api/licenses", response);
                      setSeverity("success");
                      setAlertMessage("Saved");
                      setOpen(true);
                      getLicense();
                    } else {
                      setSeverity("error");
                      setAlertMessage("error");
                      setOpen(true);
                    }
                  }).catch(function (error) {
                    console.log("err", error);
                    setSeverity("error");
                    setAlertMessage("error");
                    setOpen(true);
                  });
                }, 600);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  let startDate = new Date(newData.startDate);
                  startDate.setDate(startDate.getDate() + Number.parseInt(newData.daysDuration)); //newData.daysDuration
                  let endDate = startDate.toISOString();
                  axios({
                    method: 'post',
                    url: serverURL + '/api/licenses/' + oldData.id + "/replace",
                    params: {
                      access_token: props.authToken
                    },
                    data: {
                      clientID: parseInt(props.id),
                      startDate: newData.startDate || undefined,
                      endDate: endDate || undefined,
                      daysDuration: parseInt(newData.daysDuration) || undefined,
                      type: newData.type || undefined,
                      amount: parseInt(newData.amount) || undefined,
                      due: parseInt(newData.due) || undefined
                    },
                    headers: {
                      'authorization': `Bearer ${props.authToken}`
                    }
                  }).then(function (resp) {
                    if (resp.status == 200) {
                      const response = resp.data;
                      console.log("put /api/licenses/" + oldData.id + "/replace", response);
                      setSeverity("success");
                      setAlertMessage("Saved");
                      logs("update", 'license ' + JSON.stringify(oldData));
                      setOpen(true);
                      getLicense();
                    } else {
                      setSeverity("error");
                      setAlertMessage("error");
                      setOpen(true);
                    }
                  }).catch(function (error) {
                    console.log("err", error);
                    setSeverity("error");
                    setAlertMessage("error");
                    setOpen(true);
                  });
                }, 600);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  console.log("delete:", oldData);
                  axios({
                    method: 'delete',
                    url: serverURL + '/api/licenses/' + oldData.id,
                    params: {
                      access_token: props.authToken
                    },
                    headers: {
                      'authorization': `Bearer ${props.authToken}`
                    }
                  }).then(function (resp) {
                    if (resp.status == 200) {
                      setSeverity("success");
                      setAlertMessage("Saved");
                      logs("delete", 'license ' + JSON.stringify(oldData));
                      setOpen(true);
                      getLicense();
                    } else {
                      setSeverity("error");
                      setAlertMessage("error");
                      setOpen(true);
                    }
                  }).catch(function (error) {
                    console.log("err", error);
                    setSeverity("error");
                    setAlertMessage("error");
                    setOpen(true);
                  });
                }, 600);
              }),
          }}
        />
      }
      <br />
      {seats !== null &&
        <MaterialTable
          title="Seats Manager"
          columns={[
            { title: 'Firstname', field: 'firstname' },
            { title: 'Lastname', field: 'lastname' },
            { title: 'Email', field: 'email', render: rowData => <input type="text" value={rowData.email} style={{ fontFamily: 'monospace', width: showMail ? 400 : 'auto', position: showMail ? 'absolute' : 'relative', marginTop: -11 }} onMouseOver={() => { setShowMail(true); }} onMouseOut={() => { setShowMail(false); }} /> },
            { title: 'Password', field: 'password', render: rowData => <input type="text" value={rowData.password} style={{ fontFamily: 'monospace' }} />, initialEditValue: Math.random().toString(36).substring(5) },
            { title: 'Type', field: 'type', type: 'numeric', lookup: { 'adminpro': 'ADMIN PRO', 'broker': 'BROKER' }, initialEditValue: 'broker' },
            /*
              title: 'Access',
              field: 'access',
              lookup: { 'corporate': 'Corporate', 'individual': 'Individual', 'individual+corporate': 'Corporate + Individual' },
              initialEditValue: 'individual+corporate'
            },*/
            {
              title: 'Status',
              render: rowData =>
                <span>
                  {rowData &&
                    <span>
                      <Tooltip title={rowData.individualStatus ? new Date(rowData.individualStatus).toDateString() : "Not Created"}>
                        <span style={{ color: rowData.individualStatus ? 'green' : 'red', display: rowData.access == 'corporate' ? 'none' : 'flex', fontSize: 9 }}><AccountBoxIcon />CREATED</span>
                      </Tooltip>
                      {/*
                    <Tooltip title={rowData.CorporateStatus?new Date(rowData.CorporateStatus).toDateString():"Not Created"}>
                      <span style={{color: rowData.CorporateStatus?'green':'red', display: rowData.access=='individual'?'none':'flex', fontSize: 9}}><AccountBoxIcon/>CORP</span>
                    </Tooltip>
                    */}
                      <Tooltip title={rowData.mailStatus ? new Date(rowData.mailStatus).toDateString() : "Not Sent"}>
                        <span style={{ color: rowData.mailStatus ? 'green' : 'red', display: 'flex', fontSize: 9 }}><MailIcon />SENT</span>
                      </Tooltip>
                    </span>
                  }
                </span>,
              editable: 'never'
            }
          ]}
          data={seats ? seats : []}
          icons={{
            ...tableIcons,
            Add: forwardRef((props, ref) => <button style={{ padding: 2 }}><AddBox {...props} ref={ref} style={{ fontSize: 35, color: 'green' }} /><div style={{ fontSize: 16 }}>Add Seat</div></button>)
          }}
          actions={[
            rowData => ({
              icon: tableIcons.ExitToApp,
              tooltip: 'Open ThinkInsurcare Broker',
              onClick: (event, rowData) => {
                axios({
                  method: 'post',
                  url: 'https://individuals.thinkinsurcare.com/api/Users/Impersonate',
                  params: {
                    goldKey: 'cXxAxrY5Q2NellI1g3y75aMWPiJBtnXO',
                    email: rowData.email
                  }
                }).then(function (resp) {
                  if (resp.status == 200) {
                    const response = resp.data;
                    console.log("POST /api/Users/Impersonate", response);
                    window.open('https://individuals.thinkinsurcare.com/', '_blank');
                  }
                }).catch(function (error) {
                  console.log("err", error);
                });
              }
            }),
            rowData => ({
              icon: MailIcon,
              tooltip: 'Send Logins to ' + rowData.email,
              onClick: () => {
                axios({
                  method: 'post',
                  url: 'https://api.eu.mailgun.net/v3/mg.thinkinsurtech.com/messages',
                  auth: {
                    username: 'api',
                    password: '2528cfc6cd47912baaac7fe13f0ccbb1-1053eade-9d8b5402'
                  },
                  params: {
                    from: 'Team Think Insurcare <team@thinkinsurcare.com>',
                    to: rowData.email,
                    bcc: 'team@thinkinsurcare.com, aocinsurancebroker@pipedrivemail.com',
                    subject: 'Welcome to ThinkInsurcare SaaS dedicated to Health Insurance Brokers!',
                    html: `<p>
                        <a href='thinkinsurcare.com'>
                        <img src='https://res.cloudinary.com/toton007/image/upload/v1683716964/ThinkCareImage_ulkic4.jpg'
                        style="height:300px;width:600px;" />
                        </a>
                        <br><br>
                        Welcome Onboard to ThinkInsurCare B2B Platform to engage your client with our ThinkCare International Medical insurance plan for individuals/families and corporate.<br>
                        <br>
                        This email contains important information about your free access trial.<br>
                    
                        To access Think Insurtech platforms:<br>
                        <br><br>
                        • Go to <a href='https://corporate.thinkinsurcare.com'>https://corporate.thinkinsurcare.com</a> to access the platform<br>
                        <ul>
                          <li>Login using <span style="font-family: monospace; font-size: 18px;"><strong>${rowData.email}</strong></span></li>
                          <li>Your password is <span style="font-family: monospace; font-size: 18px;"><strong>${rowData.password}</strong></span></li>
                        </ul>
                        <br>
                        <br>
                        ${rowData.access === 'individual+corporate' ? `
                        You will have access to both platforms:<br>
                        - ThinkInsurcare Individual<br>
                        - ThinkInsurcare Corporate<br>
                        <br>`: ''}
                        If you encounter a problem, contact our technical support <a href='mailto:support@thinkinsurcare.com'>support@thinkinsurcare.com</a> - Tel: <strong>+33 1 76 21 10 65</strong> or the think insurtech team <a href='mailto:team@thinkinsurcare.com'>team@thinkinsurcare.com</a> - Tel : <strong>+33 1 76 21 10 69</strong> .<br>
                        <br>
                        We remain at your disposal for any questions, comparisons in relation to our SaaS Think Insurtech platform representing 32 players and 3 Master Broker agreement in the international health insurance market,<br>
                        Follow our news feed on <a href='https://www.youtube.com/channel/UCVZxfWIm3BMIKM-RbOdH2Ig/videos'>Youtube</a>, <a href='https://twitter.com/thinkinsurcare'>Twitter</a> and <a href='https://www.linkedin.com/in/international-health-insurance-saas-platform-457065190/'>Linkedin</a><br>
                        <br>
                        Best Regards,<br>
                        Think Insurcare Team<br>
                        <br>
                        <a href='thinkinsurcare.com'>
                          <img src='https://res.cloudinary.com/toton007/image/upload/v1683717141/logo_kqjxx5.png' />
                        </a>
                      </p>`
                  }
                }).then(function (resp) {
                  console.log("resp", resp);
                  if (resp.status == 200) {
                    setSeverity("success");
                    setAlertMessage("MAIL " + resp.data.message);

                    axios({
                      method: 'patch',
                      url: serverURL + '/api/seats/' + rowData.id,
                      params: {
                        access_token: props.authToken
                      },
                      data: {
                        clientID: parseInt(rowData.clientID),
                        mailStatus: new Date().toISOString()
                      },
                      headers: {
                        'authorization': `Bearer ${props.authToken}`
                      }
                    }).then(function (resp) {
                      if (resp.status == 200) {
                        const response = resp.data;
                        console.log("put /api/seats/" + rowData.id, response);

                        getSeats();
                      }
                    }).catch(function (error) {
                      console.log("err", error);
                    });

                    setOpen(true);
                  } else {
                    setSeverity("error");
                    setAlertMessage("error");
                    setOpen(true);
                  }
                }).catch(function (error) {
                  console.log("err", error.message);
                  setSeverity("error");
                  setAlertMessage("MAIL: " + error.message);
                  setOpen(true);
                });
                //window.open('https://individuals.thinkinsurtech.com/','_blank');
              }
            }),
            rowData => ({
              icon: ForumIcon,
              tooltip: 'Create Tribe Access',
              onClick: () => {

                axios({
                  method: 'post',
                  url: 'https://marketplace.thinkinsurtech.com/api/v1/oauth/token',
                  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                  data: qs.stringify({
                    "grant_type": "password",
                    "client_id": "5fc6454256cfb1c8e0ab0c32-g1UHVz3S3xwlJasSnwDxURuvglQXVCWd",
                    "client_secret": "AaW8feX4ZqwCwxVr2prG3V0wxFCylQ3C04UnQQbwvmLTml1LzlWdpYBhHEy0K7uY",
                    "username": "support@thinkinsurtech.com",
                    "password": "cif3EyyvfC!#M7!"
                  })
                }).then(function (resp) {
                  if (resp.status == 200) {
                    const response = resp.data;
                    console.log("marketplace.thinkinsurtech.com/api/v1/oauth/token", response);

                    console.log("token:", response.access_token);
                    let token = response.access_token;

                    console.log("TOKEN:", token);

                    console.log({
                      "username": rowData.firstname + "" + rowData.lastname,
                      "name": rowData.firstname + " " + rowData.lastname,
                      "email": rowData.email,
                      "password": rowData.password,
                      "confirmPassword": rowData.password,
                      "role": "member"
                    });

                    axios({
                      method: 'post',
                      url: 'https://marketplace.thinkinsurtech.com/api/v1/users/',
                      headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + token
                      },
                      data: qs.stringify({
                        "username": rowData.firstname + "" + rowData.lastname,
                        "name": rowData.firstname + " " + rowData.lastname,
                        "email": rowData.email,
                        "password": rowData.password,
                        "confirmPassword": rowData.password,
                        "role": "member"
                      })
                    }).then(function (resp) {
                      if (resp.status == 200) {
                        const response = resp.data;

                        console.log("ACCOUNT:", response);
                        setAlertMessage(response);
                      }
                    }).catch(function (error) {
                      console.log("err", error);
                    });
                  }
                }).catch(function (error) {
                  console.log("err", error);
                });

              }
            }),
            rowData => ({
              icon: tableIcons.PersonAddIcon,
              tooltip: 'Create Login/Password on ThinkInsurcare',
              onClick: () => {
                console.log(rowData);

                axios({
                  method: 'post',
                  url: backend + '/People/login',
                    data: { // temporaire
                      email: "vinod@codescastle.com",
                      password: "#Qwer123"
                    }
                }).then(function (resp) {
                  if (resp.status == 200) {
                    const response = resp.data;
                    console.log("get token", response.id);
                    let token = response.id;

                    console.log("type:", adminType(rowData.type))

                    axios({
                      method: 'post',
                      url: backend + '/People',
                      headers: {
                        Authorization: token
                      },
                      data: {
                        "email": rowData.email,
                        "password": rowData.password,
                        "username": rowData.email,
                        "type": adminType(rowData.type),
                        "enabled": true,
                        "firstName": rowData.firstname,
                        "lastName": rowData.lastname,
                        "agencyId": Number(props.id)
                      }
                    }).then(function (resp) {
                      console.log(backend + '/People');
                      console.log("resp", resp);
                      if (resp.status == 200) {
                        setSeverity("success");
                        setAlertMessage("ACCOUNT CREATED");
                        setOpen(true);

                        axios({
                          method: 'patch',
                          url: serverURL + '/api/seats/' + rowData.id,
                          params: {
                            access_token: props.authToken
                          },
                          data: {
                            clientID: parseInt(rowData.clientID),
                            individualStatus: new Date().toISOString()
                          },
                          headers: {
                            'authorization': `Bearer ${props.authToken}`
                          }
                        }).then(function (resp) {
                          if (resp.status == 200) {
                            const response = resp.data;
                            console.log("put /api/seats/" + rowData.id, response);

                            getSeats();
                          }
                        }).catch(function (error) {
                          console.log("err", error);
                        });

                        getLicense();
                      } else {
                        setSeverity("error");
                        setAlertMessage("error");
                        setOpen(true);
                      }
                    }).catch(function (error) {
                      console.log("err", error.message);
                      setSeverity("error");
                      setAlertMessage(error.message + "\nAccount already exist?");
                      setOpen(true);
                    });
                  }
                }).catch(function (error) {
                  console.log("err", error);
                });
              }
            })
          ]}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  console.log("newData", newData);
                  let data = seats;
                  data.push(newData);
                  setSeats(data);
                  console.log("put /api/seats");
                  axios({
                    method: 'put',
                    url: serverURL + '/api/seats',
                    params: {
                      access_token: props.authToken
                    },
                    data: {
                      clientID: parseInt(props.id),
                      lastname: newData.lastname || undefined,
                      firstname: newData.firstname || undefined,
                      type: newData.type || undefined,
                      access: newData.access || undefined,
                      password: newData.password || undefined,
                      email: newData.email || undefined,
                      createdDate: new Date().toISOString()
                    },
                    headers: {
                      'authorization': `Bearer ${props.authToken}`
                    }
                  }).then(function (resp) {
                    if (resp.status == 200) {
                      const response = resp.data;
                      console.log("/api/seats", response);
                      setSeverity("success");
                      setAlertMessage("Saved");
                      setOpen(true);
                      getSeats();
                    } else {
                      setSeverity("error");
                      setAlertMessage("error");
                      setOpen(true);
                    }
                  }).catch(function (error) {
                    console.log("err", error);
                    setSeverity("error");
                    setAlertMessage("error");
                    setOpen(true);
                  });
                }, 600);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  axios({
                    method: 'post',
                    url: serverURL + '/api/seats/' + oldData.id + "/replace",
                    params: {
                      access_token: props.authToken
                    },
                    data: {
                      clientID: parseInt(props.id),
                      lastname: newData.lastname || undefined,
                      firstname: newData.firstname || undefined,
                      type: newData.type || undefined,
                      access: newData.access || undefined,
                      password: newData.password || undefined,
                      email: newData.email || undefined,
                      createdDate: oldData.createdDate || undefined,
                      updateDate: new Date().toISOString(),
                      individualStatus: oldData.individualStatus || undefined,
                      CorporateStatus: oldData.CorporateStatus || undefined,
                      mailStatus: oldData.mailStatus || undefined
                    },
                    headers: {
                      'authorization': `Bearer ${props.authToken}`
                    }
                  }).then(function (resp) {
                    if (resp.status == 200) {
                      const response = resp.data;
                      console.log("put /api/seats/" + oldData.id + "/replace", response);
                      setSeverity("success");
                      setAlertMessage("Saved");
                      logs("update", 'seat ' + JSON.stringify(oldData));
                      setOpen(true);
                      getSeats();
                    } else {
                      setSeverity("error");
                      setAlertMessage("error");
                      setOpen(true);
                    }
                  }).catch(function (error) {
                    console.log("err", error);
                    setSeverity("error");
                    setAlertMessage("error");
                    setOpen(true);
                  });
                }, 600);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();

                  axios({
                    method: 'post',
                    url: backend + '/People/login',
                    data: { // temporaire
                      email: oldData.email,
                      password: oldData.password
                    }
                  }).then(function (resp) {
                    if (resp.status == 200) {
                      const response = resp.data;
                      console.log("get token", response.id);
                      let token = response.id;
                      let userId = response.userId;

                      axios({
                        method: 'delete',
                        url: backend + '/People/' + userId,
                        headers: {
                          Authorization: token
                        }
                      }).then(function (resp) {
                        console.log(backend + '/People');
                        console.log("resp", resp);
                        if (resp.status == 200) {
                          setSeverity("success");
                          setAlertMessage("ACCOUNT REMOVED");
                          setOpen(true);
                        } else {
                          setSeverity("error");
                          setAlertMessage("error");
                          setOpen(true);
                        }
                      }).catch(function (error) {
                        console.log("err", error.message);
                        setSeverity("error");
                        setAlertMessage("ERROR");
                        setOpen(true);
                      });
                    }
                  }).catch(function (error) {
                    console.log("err", error);
                  });

                  axios({
                    method: 'delete',
                    url: serverURL + '/api/seats/' + oldData.id,
                    params: {
                      access_token: props.authToken
                    },
                    headers: {
                      'authorization': `Bearer ${props.authToken}`
                    }
                  }).then(function (resp) {
                    if (resp.status == 200) {
                      setSeverity("success");
                      setAlertMessage("Saved");
                      setOpen(true);
                      logs("delete", 'seat ' + JSON.stringify(oldData));
                      getSeats();
                    } else {
                      setSeverity("error");
                      setAlertMessage("error");
                      setOpen(true);
                    }
                  }).catch(function (error) {
                    console.log("err", error);
                    setSeverity("error");
                    setAlertMessage("error");
                    setOpen(true);
                  });
                }, 600);
              }),
          }}
        />
      }

    </TableContainer>
  )
});

export default Clients;
